import React from "react";
import FocusLock from "react-focus-lock";
import ReactGA from "react-ga4";

export default class ModalLoginDetails extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.modal.current.focus();
  }

  modalLinkClick = (type, event) => {
    if (type === "segment") {
      ReactGA.event("interaction", {
        custom_event_type: "Modal Click",
        custom_event_detail: "Ideal Client Segments Link Click",
        custom_event_value: event.target.textContent,
      });
    } else {
      ReactGA.event("interaction", {
        custom_event_type: "Modal Click",
        custom_event_detail: "Link Click",
        custom_event_value: event.target.textContent,
      });
    }
  };

  render() {
    return (
      <section className="modal">
        <FocusLock returnFocus>
          <div className={`modal__inner modal__inner--details`} ref={this.modal} tabIndex="0">
            <h3 className="title title--secondary">Activate Your Ideal Client Segments</h3>
            <p>Based on the results of the PRIZM® Postal Code Look Up Tool refer to the following JonesLink pages to help you create your marketing plan.</p>
            <p>Note: You can connect directly to the appropriate Ideal Client JonesLink pages from the hyperlinks below.  The hyperlinks will only connect to a Joneslink page if you are using and Edward Jones approved device.</p>
            <p>
              If one of your identified Environics PRIZM® segments is one of our 16 Edward Jones' Ideal Client Segments, you can review the high-level segment information on our <b><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002640.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("general", event)}>16 Edward Jones' Ideal Clients At-a-Glance table</a></b> (WEB6002640) and use the corresponding Edward Jones' Ideal Client Segment Toolkit to help create your marketing plan. The 16 Edward Jones Ideal Client Segments are:
            </p>
            <ul>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6003005.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Asian Achievement</a> (Segment 10)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002546.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Asian Sophisticates</a> (Segment 03)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002543.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Boomer Bliss</a> (Segment 09)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002873.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Country Traditions</a> (Segment 26)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002876.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Downtown Verve</a> (Segment 06)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002880.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>First-class Families</a> (Segment 05)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002883.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Kick-back Country</a> (Segment 14)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002885.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)} >Mature and Secure</a> (Segment 07)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002888.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Mid-city Mellow</a> (Segment 23)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002890.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Multicultur-ish</a> (Segment 08)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002065.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Multural Corners</a> (Segment 18)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002066.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Savvy Seniors</a> (Segment 16)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002893.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Scenic Retirement</a> (Segment 21)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6003007.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>The A-List</a> (Segment 01)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002895.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Turbo Burbs</a> (Segment 04)</li>
              <li><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002897.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("segment", event)}>Wealthy and Wise</a> (Segment 02)</li>
            </ul>
            <p>
              If your identified Environics PRIZM® segment <b>is not</b> one of our 16 Edward Jones' Ideal Client Segments, you can use our <b><a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB6002741.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("general", event)}>General Marketing Strategies</a></b> (WEB6002741) which provide guidance and tips to help you identify and connect with prospects based on traits such as urbanity, lifestyle and media preferences to help create your marketing plan.
            </p>
            <p>Click here to find the <a href="https://ejprod.sharepoint.com/sites/JL-CA-BR-Marketing-Site/SitePages/WEB5164688.aspx" target="_blank" rel="noopener noreferrer" onClick={(event) => this.modalLinkClick("general", event)}>Ideal Clients Main Page</a> (WEB5164688)</p>
            <button className="modal__inner__close" onClick={(event) => this.props.closeLoginDetails()} aria-label="Close Modal"></button>
          </div>
        </FocusLock>
      </section>
    );
  }
}
