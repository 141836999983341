import React from "react";
import SegmentDetailsSlide from "./SegmentDetailsSlide";
import Swipe from "react-easy-swipe";
import debounce from "lodash/debounce";
import ReactGA from "react-ga4";

export default class SegmentCarouselDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.advanceSegmentDebounce = debounce(this.advanceSegment, 300);
  }

  advanceSegment(direction) {
    let currentIndex = this.props.currentSegment;
    let index = this.props.segments.findIndex((item) => item["id"] === currentIndex);
    var previous, next;

    // If first item then the 'previous' segment is the last item
    if (index === 0) {
      let previousIndex = this.props.segments.length - 1;
      previous = this.props.segments[previousIndex]["id"];
    } else {
      previous = this.props.segments[index - 1]["id"];
    }

    // If the last item then the 'next' segment is the first item
    if (index === this.props.segments.length - 1) {
      next = this.props.segments[0]["id"];
    } else {
      next = this.props.segments[index + 1]["id"];
    }

    if (direction === "forward") {
      ReactGA.event("interaction", {
        custom_event_type: "Carousel Click",
        custom_event_detail: "Next Segment",
      });
      this.props.onClick(next);
    } else if (direction === "back") {
      ReactGA.event("interaction", {
        custom_event_type: "Carousel Click",
        custom_event_detail: "Previous Segment",
      });
      this.props.onClick(previous);
    }
  }

  onSwipeEnd(position) {
    console.log(position);
    if (position.x > 120) {
      this.advanceSegmentDebounce("back");
    } else if (position.x < -120) {
      this.advanceSegmentDebounce("forward");
    }
  }

  render() {
    return (
      <Swipe onSwipeMove={(position) => this.onSwipeEnd(position)} className="segment-details" id="segment-details" tagName="section" tabIndex="0">
        {this.props.segments.length > 1 ? (
          <div className="segment-details__controls segment-details__controls--left">
            <button className="segment-details__controls__button segment-details__controls__button--left" onClick={() => this.advanceSegment("back")}>
              <span className="button-arrow button-arrow--left">Previous</span>
            </button>
          </div>
        ) : null}
        <ul className="segment-details__section" id="segment-details__section">
          <SegmentDetailsSlide
            segments={this.props.segments}
            currentSegment={this.props.currentSegment}
            searchedSegment={this.props.searchedSegment}
            currentPostalCode={this.props.currentPostalCode}
            ruralName={this.props.ruralName}
            ruralSegment={this.props.ruralSegment}
          />
        </ul>
        {this.props.segments.length > 1 ? (
          <div className="segment-details__controls segment-details__controls--right">
            <button className="segment-details__controls__button segment-details__controls__button--right" onClick={() => this.advanceSegment("forward")}>
              <span className="button-arrow button-arrow--right">Next</span>
            </button>
          </div>
        ) : null}
      </Swipe>
    );
  }
}
